@import '../../assets/styles/colors';

.work-description {
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
    color: #fff;

    @media all and (min-width: 768px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @media all and (min-width: 1880px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
}

.work-description-inner {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2rem;

    @media all and (min-width: 768px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    @media all and (min-width: 1880px) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    &.dark-text {
        color: $dark-grey;
    }

    > div {
        margin-bottom: 2rem;
    }

    .big-text {
        flex-basis: 100%;
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 1.2;

        @media all and (min-width: 768px) {
            flex-basis: 50%;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1.8rem;
        }

        @media all and (min-width: 1024px) {
            flex-basis: 40%;
        }

        @media all and (min-width: 1440px) {
            flex-basis: 33.3333%;
        }

        @media all and (min-width: 1880px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            font-size: 2.6rem;
        }
    }

    .text {
        flex-basis: 100%;

        @media all and (min-width: 768px) {
            flex-basis: 50%;
            padding-left: 1rem;
            padding-right: 1rem;
            line-height: 1.5;
        }

        @media all and (min-width: 1024px) {
            flex-basis: 60%;
        }

        @media all and (min-width: 1440px) {
            flex-basis: 66.6666%;
        }

        @media all and (min-width: 1880px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    .meta {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        margin-right: -4em;

        > div {
            margin-bottom: 0.5em;
            margin-right: 4em;

            @media all and (min-width: 1440px) {
                font-size: 1rem;
            }
        }
    }

    .cta {
        margin-top: 1.5rem;
    }
}