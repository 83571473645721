.about-video {
    margin-top: 1rem;
    position: relative;

    @media all and (min-width: 1024px) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
    }

    &.fade-enter {
        opacity: 0;
    }

    &.fade-enter-active {
        opacity: 1;
        transition: opacity 1s;
    }

    &.fade-enter-done {
        .play-video-btn-wrap {
            &.show {
                display: block;
            }
        }
    }

    &.fade-exit {
        position: absolute;
        opacity: 0;

        .play-video-btn-wrap {
            display: none !important;
        }
    }

    &.fade-exit-active {
        opacity: 0;
        transition: opacity 1s;

        .play-video-btn-wrap {
            display: none !important;
        }
    }

    .main-video {
        width: 133.3333%;
        margin-left: -33.3333%;
        display: block;

        @media all and (min-width: 1024px) {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            margin-left: 0;
        }
    }
    canvas {
        position: absolute;
        top: 0;
        left: 100%;
        z-index: -1000;
    }
}

.play-video-btn-wrap {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    transform: translateY(-50%);
    display: none;
}

.play-video-btn {
    text-align: center;

    @media all and (min-width: 1024px) {
        width: calc(100% - 10em);
        margin-left: auto;
        font-size: 1.4rem;
    }

    @media all and (min-width: 1660px) {
        width: calc(100% - 14em);
    }

    @media all and (min-width: 1880px) {
        width: calc(100% - 18em);
    }

    button {
        font-size: 1.2rem;
        font-weight: bold;
        color: #fff;
        white-space: nowrap;
    
        .icon {
            width: 3em;
            height: 3em;
            display: inline-block;
            position: relative;
            padding: 0.75em 0 0.75em 0.25em;
            margin-bottom: 0.5em;
            background-color: rgba(255,255,255,0.5);
            border-radius: 50%;

            img {
                max-height: 100%;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
