@import '../../assets/styles/colors';

.next-project {
    min-height: 6em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    position: relative;
    z-index: 10;
    display: block;
    background-color: $purple;
    overflow: hidden;
    text-decoration: none;
    font-size: 4rem;
    color: #fff;
    font-weight: 800;
    line-height: 1;

    @media all and (min-width: 425px) {
        font-size: 6rem;
    }

    @media all and (min-width: 1880px) {
        font-size: 9rem;
    }

    &:hover {
        .icon {
            &:before {
                transform: translateX(0) translateY(-50%);
                opacity: 1;
                transition: transform 0.5s, opacity 0.5s;
            }
    
            &:after {
                transform: translateX(-50%) translateY(0);
                opacity: 1;
                transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
            }
        }
    }

    .icon {
        width: 0.6em;
        height: 0.6em;
        margin-top: 0.2em;
        position: relative;
        overflow: hidden;

        &:before, &:after {
            content: '';
            position: absolute;
            background-color: #fff;
        }

        &:before {
            width: 100%;
            height: 0.125em;
            top: 50%;
            left: 0;
            transform: translateX(-1em) translateY(-50%);
            opacity: 0;
            transition: transform 0.5s, opacity 0.5s;
        }

        &:after {
            width: 0.125em;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-1em);
            opacity: 0;
            transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
        }
    }

    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-position: center;
        background-size: cover;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.4);
        }
    }
}