.work-image  {
    background-position: center;
    background-size: cover;

    img {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
