@import '../../assets/styles/colors';

.career-single-content {
    &.loaded-appear {
        opacity: 0;
    }

    &.loaded-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.career-single-banner {
    min-height: 20rem;
    padding-top: 6rem;
    padding-bottom: 3rem;

    @media all and (min-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.career-single-banner-inner {
    @media all and (min-width: 768px) {
        display: flex;
        align-items: center;
    }

    .header {
        opacity: 0.2;
        color: #fff;

        @media all and (min-width: 768px) {
            width: 50%;
        }

        @media all and (min-width: 1024px) {
            width: 60%;
        }
    }
    
    .animation-wrap {
        max-width: 20rem;
        margin: 0 auto;

        @media all and (min-width: 768px) {
            width: 50%;
        }

        @media all and (min-width: 1024px) {
            width: 40%;
            max-width: 30rem;
        }

        > div {
            width: 100%;
            height: 100%;

            > div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        canvas {
            max-width: 100%;
            max-height: 20rem;

            @media all and (min-width: 1024px) {
                max-height: 30rem;
            }
        }
    }
}

.career-single-details {
    padding-top: 3rem;
    padding-bottom: 3rem;
    line-height: 1.5;

    @media all and (min-width: 768px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
        display: flex;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    @media all and (min-width: 1024px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    @media all and (min-width: 1440px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
}

.career-single-info {
    margin-bottom: 3rem;

    @media all and (min-width: 768px) {
        width: 18rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    @media all and (min-width: 1024px) {
        width: 24rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    @media all and (min-width: 1880px) {
        width: 28rem;
    }

    .meta {
        margin-bottom: 1rem;

        @media all and (min-width: 768px) {
            margin-bottom: 3rem;
            font-size: 1.2rem;
        }

        > div {
            margin-bottom: 0.25em;
            display: flex;
        }

        strong {
            min-width: 5em;
            display: inline-block;
            color: $purple;
        }
    }

    .back {
        font-size: 0.9rem;
        text-decoration: none;
    }
}

.career-single-text {
    @media all and (min-width: 768px) {
        flex: 1;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    @media all and (min-width: 1024px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .header {
        margin-bottom: 0.5em;
    }

    .plus-btn {
        margin-top: 2em;
        margin-right: 20px;
    }
}