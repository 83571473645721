.video-text-section {
  padding-top: 190px;
  padding-bottom: 190px;

  .video-text-inner {
    display: flex;
    flex-direction: column;

    @media all and (min-width: 1024px) {
      flex-direction: row;
    }

    .header-container {
      margin-right: 50px;
      margin-bottom: 50px;

      @media all and (min-width: 1024px) {
        width: 60%;
        margin-bottom: 0;
      }

      .header-sm {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
        font-size: 28px;
        line-height: 1em;
        text-transform: initial;

        @media all and (min-width: 768px) {
          font-size: 32px;
        }

        @media all and (min-width: 1024px) {
          font-size: 40px;
        }

        @media all and (min-width: 1440px) {
          font-size: 50px;
        }
      }
    }

    .content-container {
      //width: 40%;
    }
  }
}