@import '../../assets/styles/colors';

.home-contact {
    background-color: $green;
    color: #fff;

    .container {
        padding-top: 6rem;
        padding-bottom: 6rem;
        overflow: hidden;

        @media all and (min-width: 768px) {
            padding-top: 9rem;
            padding-bottom: 9rem;
        }
    }

    .header {
        margin-bottom: 1.875rem;
        opacity: 0.2;
        color: #fff;
    }

    .sub-header {
        margin-top: 0;
        max-width: 820px;
    }
}