.news-single-content {
    &.loaded-appear {
        opacity: 0;
    }

    &.loaded-appear-active {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.news-single-content {
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media all and (min-width: 768px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
}

.news-single {
    overflow: hidden;

    .meta-wrap {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    
    .meta {
        margin-bottom: -0.5em;
        margin-right: -2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 1.1rem;

        > div {
            margin-bottom: 0.5em;
            margin-right: 2em;
        }

        .icon {
            height: 1em;
            margin-bottom: -0.1em;
            margin-right: 0.5em;
        }

        a {
            text-decoration: none;
        }
    }

    .header {
        margin-bottom: 0.5em;

        @media all and (min-width: 768px) {
            font-size: 2.5rem;
        }

        @media all and (min-width: 1024px) {
            font-size: 3rem;
        }

        @media all and (min-width: 1880px) {
            font-size: 3.5rem;
        }
    }

    .featured-image {
        max-width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.news-text {
    margin-top: 3rem;

    > * {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
    }

    > .wp-block-image, .wp-block-gallery {
        max-width: 100%;
        
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }

    img {
        max-width: 100%;
        display: block;
    }
}
