@import 'assets/styles/colors';
@import 'assets/styles/normalize';
@import 'assets/styles/fonts';

*, *:before, *:after {
    box-sizing: inherit;
}

html {
    background-color: $extra-light-grey;
    font-size: 16px;
    line-height: 1.5;

    @media all and (min-width: 1440px) {
        font-size: 18px;
    }
}

body {
    box-sizing: border-box;
	background-color: #fff;
    font-family: 'Gilroy', sans-serif;
	color: $dark-grey;
    
    &:not(.user-is-tabbing) {
        button:focus, input:focus, select:focus, textarea:focus {
            outline: none;
        }
    }
}

button {
    padding: 0;
    border: none;
    background-color: transparent;
    appearance: none;
    cursor: pointer;
    font-weight: inherit;
    color: inherit;
    text-transform: inherit;
    line-height: inherit;
}

a {
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: 1.2;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

p {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

figure {
    margin: 0;
}

ul {
    margin-top: 2em;
    margin-bottom: 2em;
    padding-left: 0;
    list-style-type: none;

    @media all and (min-width: 768px) {
        padding-left: 1.5em;
    }

    li {
        position: relative;
        padding-left: 2.5em;
        margin-top: 1em;
        margin-bottom: 1em;

        &:before {
            content: '';
            width: 1.5em;
            height: 2px;
            position: absolute;
            top: 0.6em;
            left: 0;
            background-color: $dark-grey;
        }
    }
}



.container {
    max-width: 46rem;
    margin-left: auto;
    margin-right: auto;

    @media all and (min-width: 1024px) {
        max-width: 64rem;
    }

    @media all and (min-width: 1440px) {
        max-width: 90rem;
    }

    @media all and (min-width: 1880px) {
        max-width: 120rem;
    }
}

.lg-container {
    max-width: 46rem;
    margin-left: auto;
    margin-right: auto;

    @media all and (min-width: 1024px) {
        max-width: 64rem;
    }

    @media all and (min-width: 1440px) {
        max-width: 90rem;
    }
}

.md-container {
    max-width: 46rem;
    margin-left: auto;
    margin-right: auto;

    @media all and (min-width: 1024px) {
        max-width: 64rem;
    }
}

.container-padding {
    padding-left: 1rem;
    padding-right: 1rem;

    @media all and (min-width: 1024px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @media all and (min-width: 1440px) {
        padding-left: 9rem;
        padding-right: 9rem;
    }
}

.md-container-padding {
    padding-left: 1rem;
    padding-right: 1rem;

    @media all and (min-width: 1024px) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

.container-padding-md {
    padding-left: 1rem;
    padding-right: 1rem;

    @media all and (min-width: 1024px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media all and (min-width: 1440px) {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
    }
}

.no-scroll {
    overflow: hidden;
}

.full-screen {
    min-height: 100vh;
}

.sm-full-screen {
    @media all and (min-width: 768px) {
        min-height: 100vh;
    }
}

.center-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
        width: 100%;
    }
}

.header-sm {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    font-size: 2.5rem;
    line-height: 0.8;
    text-transform: uppercase;

    @media all and (min-width: 768px) {
        font-size: 3rem;
    }

    @media all and (min-width: 1024px) {
        font-size: 4rem;
    }
}

.header-md {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    font-size: 3rem;
    line-height: 0.8;
    text-transform: uppercase;

    @media all and (min-width: 768px) {
        font-size: 4rem;
    }

    @media all and (min-width: 1024px) {
        font-size: 5rem;
    }

    @media all and (min-width: 1880px) {
        font-size: 6rem;
    }
}

.header-lg {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    font-size: 3rem;
    line-height: 0.8;
    text-transform: uppercase;

    @media all and (min-width: 768px) {
        font-size: 6rem;
    }

    @media all and (min-width: 1024px) {
        font-size: 8rem;
    }

    @media all and (min-width: 1440px) {
        font-size: 10rem;
    }
}

.sub-header {
    font-size: 1.2rem;

    @media all and (min-width: 768px) {
        font-size: 1.8rem;
    }

    @media all and (min-width: 1440px) {
        font-size: 2.2rem;
    }
}

.plus-btn {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 800;
    text-decoration: underline;
    transition: opacity 0.5s;

    @media all and (min-width: 768px) {
        font-size: 1.5rem;
    }

    &:hover {
        opacity: 0.5;

        .icon {
            &:before {
                width: 0;
            }
            
            &:after {
                height: 0;
            }
        }
    }

    &.white {
        color: #fff;

        .icon {
            &:before, &:after {
                background-color: #fff;
            }
        }
    }

    &.purple {
        color: $purple;

        .icon {
            &:before, &:after {
                background-color: $purple;
            }
        }
    }

    .icon {
        width: 0.875em;
        height: 0.875em;
        // margin-top: -0.2em;
        margin-left: 0.4em;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            background-color: $dark-grey;
        }

        &:before {
            width: 100%;
            height: 0.15em;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            transition: width 0.15s ease-in;
        }

        &:after {
            width: 0.15em;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: height 0.15s 0.1s ease-in;
        }
    }
}

.animation-wrap {
    position: relative;

    div {
        &:nth-child(2) {
            display: none !important;
        }
    }

    canvas {
        width: auto !important;
        max-width: 100%;
    }
}

.input-wrap {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 800;
    color: #fff;

    label {
        margin-top: 0.25em;
        padding-right: 1em;
    }

    input {
        width: auto;
        min-width: 0;
        padding: 0.25em 0.5em;
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 0.05em solid #fff;
        flex: 1;
        color: $dark-grey;
        font-weight: 800;

        &::placeholder {
            color: rgba(255,255,255,0.5);
        }
    }

    textarea {
        flex-basis: 100%;
        min-width: 100%;
        min-height: 6em;
        margin-top: 0.25em;
        margin-bottom: 0.25em;
        padding: 0.25em 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 0.05em solid #fff;
        color: $dark-grey;
        font-weight: 800;
    }
}

.error {
    width: 100%;
    background-color: $red;
    padding: 0.25em;
    margin-bottom: 0.5em;
    font-size: 0.9rem;
    color: #fff;
    font-weight: 800;
}



.has-small-font-size {
    font-size: 0.8em;
}

.has-medium-font-size {
    font-size: 1.6em;
}

.has-large-font-size {
    font-size: 1.8em;
}

.has-huge-font-size {
    font-size: 2em;
}

.wp-block-image {
    margin-top: 4em;
    margin-bottom: 4em;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.wp-block-gallery {
    margin-top: 4em;
    margin-bottom: 4em;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.is-cropped {
        .blocks-gallery-item {
            > figure {
                height: 100%;
            }

            img {
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 1em;
    }

    .blocks-gallery-grid {
        @media all and (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -1em;
            margin-left: -0.5em;
            margin-right: -0.5em;
        }
    }

    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            padding-left: 0.5em;
            padding-right: 0.5em;
            flex: 1;
        }
    }
}

.blocks-gallery-item {
    padding-left: 0;
    margin-top: 0;
    
    &:before {
        content: none;
    }
}

.columns-1, .columns-2, .columns-3, .columns-4, .columns-5, .columns-6, .columns-7, .columns-8 {
    .blocks-gallery-item {
        flex-basis: 100%;
    }
}

.columns-2 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 50%;
        }
    }
}

.columns-3 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 33.3333%;
        }
    }
}

.columns-4 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 25%;
        }
    }
}

.columns-5 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 20%;
        }
    }
}

.columns-6 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 16.6666%;
        }
    }
}

.columns-7 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 14.2857%;
        }
    }
}

.columns-8 {
    .blocks-gallery-item {
        @media all and (min-width: 768px) {
            flex-basis: 12.5%;
        }
    }
}

.wp-block-quote {
    max-width: 32em;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 1.2em;

    @media all and (min-width: 768px) {
        font-size: 1.6em;
    }

    @media all and (min-width: 1024px) {
        font-size: 1.8em;
    }

    @media all and (min-width: 1440px) {
        font-size: 2em;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:before, &:after {
        @media all and (min-width: 768px) {
            height: 2.2rem;
        }

        @media all and (min-width: 1024px) {
            height: 2.8rem;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 2rem;
        display: block;
        background-image: url('./assets/icons/open-quote-icon.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
    }

    &:after {
        content: '';
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        background-image: url('./assets/icons/close-quote-icon.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        display: inline-block;
        vertical-align: top;

        @media all and (min-width: 540px) {
            width: 50%;
        }
    }

    p {
        width: 100%;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    cite {
        width: calc(100% - 2rem);
        display: inline-block;
        vertical-align: top;
        font-style: normal;
        font-size: 0.7em;

        @media all and (min-width: 540px) {
            width: 50%;
        }
    }
}

.scroller-wrapper {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 10;

    .scroller {
        width: 37px;
        height: 62px;
        border-radius: 19px;
        border: solid 4px #fff;
        margin: auto;
        
        span {
            display: block;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: #fff;
            margin: 8px auto 0;
            animation-name: mouseScroll;
            animation-duration: 2.2s;
            animation-iteration-count: infinite;
        }
    }

    .scroller-text {
        font-size: 1rem;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #fff;
        text-align: center;
        margin-top: 5px;
    }
}

@keyframes mouseScroll {
    0% { 
        opacity: 0; 
    }

    10% { 
        transform: translateY(0); 
        opacity: 1; 
    }

    100% { 
        transform: translateY(15px); 
        opacity: 0;
    }
}

.text-center {
    text-align: center;
}