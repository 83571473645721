.work-content {
    padding-top: 6rem;
    padding-bottom: 6rem;
    
    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.work-top {
    .header {
        margin-bottom: 0.25em;
        text-transform: uppercase;
    }
}

.work-filter {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 3000;
    opacity: 0;
    background-color: rgba(0,0,0,0.6);
    overflow: auto;
    transition: top 0s 0.5s, opacity 0.5s;

    &.show {
        top: 0;
        opacity: 1;
        transition: opacity 0.5s;

        .work-filter-inner {
            transform: translateY(0);
            transition: transform 0.5s;
        }
    }
}

.work-filter-inner {
    width: 100%;
    max-height: 100%;
    position: relative;
    transform: translateY(-2rem);
    padding-top: 6rem;
    padding-bottom: 6rem;
    font-weight: 800;
    font-size: 2rem;
    color: #fff;
    text-transform: uppercase;
    transition: transform 0.5s;

    a {
        text-decoration: none;
    }

    sup {
        margin-left: 1em;
        top: -1em;
        font-size: 0.4em;
        font-weight: normal;
    }
}