@import '../../assets/styles/colors';

.work-collaborate {
    background-color: $extra-dark-grey;
}

.work-collaborate-inner {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;

    @media all and (min-width: 1024px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.work-collaborate-text {
    margin-bottom: 0.25em;
    color: #fff;
    font-weight: 800;
    font-size: 3rem;
    line-height: 0.9;

    @media all and (min-width: 768px) {
        font-size: 6rem;
    }

    @media all and (min-width: 1024px) {
        font-size: 8rem;
    }

    @media all and (min-width: 1440px) {
        font-size: 10rem;
    }
}
