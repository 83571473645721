@import '../../assets/styles/colors';

.contact-cta {
  .plus-btn {
    .icon {
      &:before, &:after {
        background-color: #fff;
      }
    }
  }

  .contact-content {
    &.fade-appear {
      opacity: 0;
    }

    &.fade-appear-done {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  .contact {
    padding-top: 6rem;
    padding-bottom: 6rem;
    background-color: $green;
    overflow: hidden;
    color: #fff;

    @media all and (min-width: 768px) {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }

    .header {
      margin-bottom: 0.5em;
      opacity: 0.2;
      color: #fff;
    }
  }

  .contact-details-wrap {
      display: flex;
      align-items: center;
  }

  .contact-details {

    .contact-details-text {
      font-size: 32px;
      line-height: 1.1em;

      @media all and (min-width: 1224px) {
        font-size: 40px;
      }
    }
  }

  .contact-animation {
    margin-left: -1rem;
    margin-right: -1rem;

    @media all and (min-width: 768px) {
      width: 68%;
      margin-left: 0;
      margin-right: -40%;
      flex: 1;
    }
  }

  .google-maps-link {
    a {
      font-size: 0.6em;
      text-decoration: underline;
    }
  }

  .contact-send-message {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    background-color: $dark-green;
    font-size: 1.5rem;
  }
}
