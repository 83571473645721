@import '../../assets/styles/colors';
@import '../../assets/styles/slick';

.home-work {
    margin-top: 6rem;
    margin-bottom: 6rem;
    overflow: hidden;

    @media all and (min-width: 1024px) {
        margin-top: 9rem;
        margin-bottom: 9rem;
    }

    @media all and (min-width: 1440px) {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }
    
    .slider {
        margin-bottom: 1.5rem;
        font-size: 0;

        @media all and (min-width: 768px) {
            margin-bottom: 3rem;
        }

        .slick-slide {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .slick-prev, .slick-next {
            width: calc(15% - 0.5rem);
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 10;

            @media all and (min-width: 520px) {
                width: calc(30% - 0.5rem);
            }
        }

        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }

    .slide {
        padding-bottom: 100%;
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1rem;
    }

    .slider-inner {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $purple;
        overflow: hidden;
        color: #fff;
        text-align: center;
        text-decoration: none;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 20;
            background-color: rgba(0,0,0,0.5);
            opacity: 1;
            transition: opacity 0.5s;
        }

        &:hover {
            .slide-text-inner, .background {
                transform: scale(1);
                transition: transform 1s;
            }
        }

        .icon {
            width: 9vw;
            height: 9vw;
            position: absolute;
            top: 5vw;
            right: 5vw;
            overflow: hidden;

            @media all and (min-width: 520px) {
                width: 5vw;
                height: 5vw;
                top: 2vw;
                right: 2vw;
            }

            &:before, &:after {
                content: '';
                position: absolute;
                background-color: #fff;
            }

            &:before {
                width: 100%;
                height: 1.5vw;
                top: 50%;
                left: 0;
                transform: translateX(100%) translateY(-50%);
                opacity: 0;
                transition: transform 0.25s, opacity 0.25s;

                @media all and (min-width: 520px) {
                    height: 1vw;
                }
            }

            &:after {
                width: 1.5vw;
                height: 100%;
                top: 0;
                left: 50%;
                transform: translateX(-50%) translateY(-100%);
                opacity: 0;
                transition: transform 0.25s 0.1s, opacity 0.25s 0.1s;

                @media all and (min-width: 520px) {
                    width: 1vw;
                }
            }
        }

        .slide-text {
            width: 100%;
            position: relative;
            font-size: 11vw;
            line-height: 0.9;
            font-weight: 800;
            text-transform: uppercase;

            @media all and (min-width: 520px) {
                font-size: 5.5vw;
            }

            @media all and (min-width: 1440px) {
                font-size: 4vw;
            }

            .arrows {
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 20;
                display: flex;
                justify-content: space-between;
                padding-top: 0.5rem;

                @media all and (min-width: 520px) {
                    flex-direction: row-reverse;
                    padding-left: calc(23% + 0.5rem);
                    padding-right: calc(23% + 0.5rem);
                }

                @media all and (min-width: 768px) {
                    padding-left: calc(23% + 2rem);
                    padding-right: calc(23% + 2rem);
                }

                @media all and (min-width: 1024px) {
                    padding-top: 2vw;
                    padding-left: calc(23% + 3rem);
                    padding-right: calc(23% + 3rem);
                }

                @media all and (min-width: 1440px) {
                    padding-left: calc(23% + 4.5rem);
                    padding-right: calc(23% + 4.5rem);
                }

                .arrow-right, .arrow-left {
                    width: 0.6em;
                    transform: translateX(0);
                    opacity: 1;
                    transition: transform 0.5s, opacity 0.5s;
                }

                .arrow-right {
                    opacity: 0;
                }
            }
        }

        .slide-text-inner {
            transform: scale(0.95);
            transition: transform 0.5s;
        }

        .background {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            transform: scale(1);
            transition: transform 0.5s;
            background-position: center;
            background-size: cover;
        }
    }

    .slick-current {
        .slider-inner {
            &:before {
                opacity: 0;
            }
        }

        .icon {
            &:before {
                transform: translateX(0) translateY(-50%);
                opacity: 1;
                transition: transform 0.25s 0.5s, opacity 0.25s 0.5s;
            }

            &:after {
                transform: translateX(-50%) translateY(0);
                opacity: 1;
                transition: transform 0.25s 0.6s, opacity 0.25s 0.6s;
            }
        }

        .slide-text {
            .arrows {
                .arrow-right, .arrow-left {
                    opacity: 0;
                }

                .arrow-right {
                    transform: translateX(4vw);
                }

                .arrow-left {
                    transform: translateX(-4vw);
                }
            }
        }

        .background {
            transform: scale(1.1);
        }

        ~ .slick-slide {
            .slide-text {
                .arrows {
                    .arrow-right {
                        opacity: 1;
                    }

                    .arrow-left {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .home-work-btn {
        margin-top: 1.5rem;

        @media all and (min-width: 768px) {
            margin-top: 3rem;
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}