.progress-bar-container {
  width: 100%;
  font-weight: bold;
  margin-bottom: 80px;

  .progress-value {
    font-size: 60px;
    line-height: 1;

    @media all and (min-width: 1024px) {
      font-size: 96px;
    }
  }

  .progress-bar {
    background: white;
    height: 20px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    .bar {
      transition: all ease 3s;
      //transition-delay: 800ms;
      border-radius: 20px;
      height: 100%;
      background: #1FC2AF;
    }
  }

  .progress-text {
    font-size: 18px;

    @media all and (min-width: 1024px) {
      font-size: 26px;
    }
  }
}