.work-blockquote {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media all and (min-width: 768px) {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    &.white-text {
        color: #fff;

        .wp-block-quote {
            &:before {
                background-image: url('../../assets/icons/open-quote-white-icon.svg');
            }
        
            &:after {
                background-image: url('../../assets/icons/close-quote-white-icon.svg');
            }
        }
    }

    .wp-block-quote {
        margin-left: auto;
        margin-right: auto;
    }
}
