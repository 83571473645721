@import '../../assets/styles/colors';

.home-news {
    background-color: $extra-dark-grey;
    color: #fff;

    .container {
        margin-top: 6rem;
        margin-bottom: 0;
        padding-top: 6rem;
        padding-bottom: 6rem;
        overflow: hidden;

        @media all and (min-width: 768px) {
            padding-top: 9rem;
            padding-bottom: 9rem;
        }

        @media all and (min-width: 1024px) {
            margin-top: 9rem;
            margin-bottom: 0;
        }

        @media all and (min-width: 1440px) {
            margin-top: 12rem;
            margin-bottom: 0;
        }
    }

    .header-lg {
        margin-bottom: 1.875rem;
        opacity: 0.2;
        color: #fff;
    }
}

.home-news-posts {
    @media all and (min-width: 576px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    @media all and (min-width: 768px) {
        margin: 0 -25px;
    }

    // @media all and (min-width: 1024px) {
    //     margin: 0 -50px;
    // }

    .post {
        margin-bottom: 30px;

        @media all and (min-width: 576px) {
            flex: 1 0 50%;
            margin: 0 15px 30px;
            max-width: calc(50% - 30px);
        }

        @media all and (min-width: 768px) {
            flex: 1 0 33.33333%;
            margin: 0 25px;
            max-width: calc(33.33333% - 50px);
        }

        // @media all and (min-width: 1024px) {
        //     flex: 1 0 25%;
        //     margin: 0 45px 30px;
        //     max-width: calc(25% - 90px);
        // }

        > * {
            text-decoration: none;
        }

        img {
            display: block;
            max-width: 100%;
        }

        .post-date {
            font-size: 0.875rem;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            margin-top: .5em;
            margin-bottom: .5em;
        }

        .post-title {
            margin-top: 0;
            margin-bottom: .5em;
            font-size: 1.2rem;

            @media all and (min-width: 1024px) {
                font-size: 1.5rem;
            }
        }

        .post-link {
            font-size: 1rem;

            @media all and (min-width: 1024px) {
                font-size: 1.2rem;
            }
        }
    }
}