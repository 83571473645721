@import '../../assets/styles/colors';

.about-step-text {
    font-weight: bold;
    transition: color 0.5s;

    @media all and (min-width: 768px) {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    @media all and (min-width: 1024px) {
        font-size: 1.2rem;
    }

    &.fade-enter-done {
        opacity: 1;
    }

    &.fade-enter {
        transform: translateY(1rem);
        opacity: 0;
    }

    &.fade-enter-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
    }

    &.fade-exit {
        opacity: 0;
        position: absolute;
    }

    &.white {
        color: #fff;
    }

    &.dark-grey {
        color: $dark-grey;
    }

    .text {
        line-height: 1.2;

        @media all and (min-width: 768px) {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media all and (min-width: 1024px) {
            max-width: 16em;
        }

        @media all and (min-width: 1880px) {
            max-width: 18em;
            font-size: 1.4rem;
        }
    }

    .plus-btn {
        margin-top: 3rem;

        @media all and (min-width: 1024px) {
            margin-top: 6rem;
        }
    }
}

.about-step-no {
    margin-bottom: 1.5em;
    font-weight: normal;
}

.about-step-btns {
    a, button {
        margin-right: 2em;
    }
}

.about-mute-btn {
    margin-top: 2em;
    opacity: 1;
    transition: opacity 0.25s;

    &:hover {
        opacity: 0.5;
    }

    &.mute {
        .soundwave-1, .soundwave-2, .soundwave-3 {
            opacity: 0;
        }

        .soundwave-1 {
            transition-delay: 0.2s;
        }
    
        .soundwave-2 {
            transition-delay: 0.1s;
        }
    
        .soundwave-3 {
            transition-delay: 0s;
        }
    }

    &.dark-grey {
        svg {
            fill: $dark-grey;
        }
    }

    svg {
        height: 2rem;
        display: block;
        fill: #fff;
        transition: fill 0.5s
    }

    .soundwave-1, .soundwave-2, .soundwave-3 {
        opacity: 1;
        transition: opacity 0.25s;
    }

    .soundwave-2 {
        transition-delay: 0.1s;
    }

    .soundwave-3 {
        transition-delay: 0.2s;
    }
}