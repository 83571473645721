@import '../../assets/styles/colors';

#pre-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    background-color: $purple;
    opacity: 1;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: top 0s 0.5s, opacity 0.5s;

    &.hide {
        top: 100%;
        opacity: 0;
    }

    canvas {
        max-width: 150px;
    }
}

#pre-loader-monster {
    width: 10rem;
}
