.ecommerce-banner-container {
  .ecommerce-banner {
    min-height: 80vh;
    height: 100%;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F8F8F9;
    flex-direction: column;

    @media all and (min-width: 1024px) {
      flex-direction: row;
    }

    .ecommerce-list {
      font-weight: normal;
      margin-bottom: 50px;

      @media all and (min-width: 1224px) {
        font-size: 26px;
        margin-bottom: 0;
      }

      @media all and (min-width: 1880px) {
        font-size: 32px;
      }

      ul {
        padding: 0;

        li {
          margin: 0;
        }
      }
    }

    .ecommerce-banner-left, .ecommerce-banner-right {
      width: 100%;

      .header-lg {
        @media all and (min-width: 1024px) {
          font-size: 80px !important;
        }

        //@media all and (min-width: 1240px) {
        //  font-size: 100px !important;
        //}

        @media all and (min-width: 1880px) {
          font-size: 120px !important;
        }
      }
    }

    .ecommerce-banner-left {
      padding-right: 40px;

      @media all and (min-width: 1024px) {
        width: 65%;
      }
    }

    .ecommerce-banner-right {
      background: #a15151;
      height: 80vh;

      @media all and (min-width: 1024px) {
        width: 35%;
      }
    }

    .dots-container {
      li {
        &:before {
          display: none;

        }

        button {
          &:before {
            transition: all ease 0.3s;
            font-size: 18px;
            color: #fff;
          }
        }
      }
    }

    .slide-container {
      .slide-bg {
        height: 80vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        //padding-top: 100%;
      }
    }
  }

  .scroller-wrapper {
    background: #eee;
    height: 0px;

    @media all and (max-width: 1024px) {
      display: none;
    }

    .scroller {
      border: solid 4px #414042;

      span {
        background: #414042;
      }
    }

    .scroller-text {
      color: #414042;
    }
  }
}

