
.phone-video-container {

  .react-reveal {
    opacity: 1 !important;
    transition: all ease 0.5s !important;
    //transform: scale(2);
  }

  .background-red {
    background: red;
  }


  .phone-video {
    position: relative;

    .phone-video-inner {
      //width: 319px;

      margin: auto;
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;

      video {
        overflow: hidden;
        border-radius: 45px;
        border: #090909 11px solid;
        //border: red 11px solid;
      }
    }

    .iphone-border {
      position: absolute;
      top: 0;
      height: 35px;
      background: #090909;
      left: 33.33%;
      width: 33.33%;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      z-index: 2;
    }
  }
}

.fade-in-image {
  //animation: fadeIn 5s;
  //animation-fill-mode: forwards;
}

//@keyframes fadeIn {
//  0% {
//    transform: scale(2);
//  }
//
//  100% {
//    transform: scale(1);
//  }
//}


