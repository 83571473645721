.work-single-content {
    &.loaded-appear {
        opacity: 0;
    }

    &.loaded-appear-active {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.work-single {
    display: flex;
    flex-direction: column;

    > * {
        width: 100%;
    }
}
