@import '../../assets/styles/colors';

.privacy-policy-content {
    padding-top: 6rem;
    padding-bottom: 6rem;

    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.privacy-policy {
    > div {
        margin-bottom: 6rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .header {
        margin-bottom: 0;
        font-size: 3rem;
    }

    .sub-header {
        margin-top: 0.5em;
        font-weight: normal;
        color: $grey;
    }

    .number-header {
        text-transform: uppercase;
        color: $grey;

        strong {
            color: $extra-dark-grey;
        }
    }
}
