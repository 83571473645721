.text-logo-section {
  background: #414042;
  padding-top: 190px;
  padding-bottom: 190px;

  .logo-row {
    margin-bottom: 150px;

    .logo-row-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -10px;

      .logo-container {
        margin: 10px 10px 50px;
        width: calc(100% - 20px);

        @media all and (min-width: 740px) {
          width: calc(100%/2 - 20px);
        }

        @media all and (min-width: 1024px) {
          width: calc(100%/3 - 20px);
        }

        @media all and (min-width: 1240px) {
          width: calc((100%/6) - 20px);
        }

        img {

          width: 100%;
        }
      }
    }
  }

  .text-logo-inner {
    display: flex;
    flex-direction: column;
    color: #fff;

    @media all and (min-width: 1024px) {
      flex-direction: row;
    }

    .header-container {
      margin-right: 50px;

      @media all and (min-width: 1024px) {
        width: 40%;
      }

      .header-sm {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
        font-size: 28px;
        line-height: 1em;
        text-transform: initial;

        @media all and (min-width: 768px) {
          font-size: 32px;
        }

        @media all and (min-width: 1024px) {
          font-size: 40px;
        }

        @media all and (min-width: 1440px) {
          font-size: 50px;
        }
      }
    }

    .content-container {
      @media all and (min-width: 1024px) {
        width: 60%;
      }
    }
  }
}