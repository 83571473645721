.large-slide-container {
  height: 100vh;
  position: relative;

  .slider-header {
    position: absolute;
    z-index: 2;
    top: 100px;
    left: 30px;
    width: 50%;

    @media all and (min-width: 1024px) {
      top: 100px;
      left: 100px;
    }

    * {
      transition: all ease 0.3s;
    }

    .text-white {
      color: #fff;
    }
  }

  .slide-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .arrow-container {
    position: absolute;
    bottom: 100px;
    right: 30px;
    display: flex;

    @media all and (min-width: 1024px) {
      bottom: 100px;
      right: 100px;
    }


    .arrow-icon {
      cursor: pointer;
      position: relative;
      width: 50px;

      &:not(:last-child) {
        margin-right: 50px;
      }

      img {
        position: absolute;
        top: 0;
        width: 100%;
        transition: all ease 0.3s;

        &.hidden {
          opacity: 0;
        }
      }
    }
  }
}
