@import '../../assets/styles/colors';
@import '~lity/dist/lity.css';

.service-content {
    position: relative;
    z-index: 10;
    background-color: $extra-dark-grey;
    transition: background-color 0.5s;
    padding-bottom: 50px;

    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s, background-color 0.5s;
    }

    .scroller-wrapper {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.service-inner {
    padding-top: 6rem;
    position: relative;
    z-index: 2000;

    @media all and (min-width: 768px) {
        padding-top: 0;
        display: flex;
    }
}

.service {
    padding-bottom: 3rem;

    @media all and (min-width: 768px) {
        width: 70%;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @media all and (min-width: 1024px) {
        width: 66.6666%;
        padding-top: 9rem;
        padding-bottom: 9rem;
    }

    @media all and (min-width: 1440px) {
        width: 70%;
    }
}

.service-nav {
    padding-bottom: 3rem;

    @media all and (min-width: 768px) {
        width: 30%;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @media all and (min-width: 1024px) {
        width: 33.3333%;
        padding-top: 9rem;
        padding-bottom: 9rem;
    }

    @media all and (min-width: 1440px) {
        width: 30%;
    }
}

.service-nav-inner {
    font-size: 1.5rem;
    line-height: 0.9;
    font-weight: 800;
    color: rgba(255,255,255,0.4);
    text-transform: uppercase;

    @media all and (min-width: 1440px) {
        font-size: 2rem;
    }

    a {
        position: relative;
        display: inline-block;
        text-decoration: none;
        transition: color 0.5s;

        &.active, &:hover {
            color: #fff;
        }

        &.has-sub-nav {
            padding-right: 1em;

            &.active, &:hover {
                > span {
                    transform: translateX(0.75em);
                }

                .icon {
                    transform: translate(0.75em, -50%);
                    opacity: 1;
                }
            }
        }

        > span {
            display: inline-block;
            transform: translateX(0);
            transition: transform 0.25s;
        }

        .icon {
            width: 0.5em;
            height: 0.5em;
            position: absolute;
            top: 50%;
            left: -0.75em;
            transform: translate(0, -50%);
            opacity: 0;
            transition: transform 0.25s, opacity 0.25s;
            pointer-events: none;

            &:before, &:after {
                content: '';
                position: absolute;
                background-color: #fff;
            }
    
            &:before {
                width: 100%;
                height: 0.15em;
                top: 0.175em;
                left: 0;
                transition: width 0.15s ease-in;
            }
    
            &:after {
                width: 0.15em;
                height: 100%;
                top: 0;
                left: 0.175em;
                transition: height 0.15s 0.1s ease-in;
            }
        }
    }
}

.service-nav-item {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.sub-service-nav {
    margin-top: 0.25em;
    padding-left: 0.75em;
}

.sub-service-nav-item {
    margin-top: 0.25em;
    margin-bottom: 0.25em;

    @media all and (min-width: 768px) {
        margin-top: 0.35em;
        margin-bottom: 0.35em;
        font-size: 0.8em;
    }

    @media all and (min-width: 1440px) {
        font-size: 0.6em;
    }
}

.service-details {
    opacity: 0;
    transition: opacity 0.5s;

    &.fade-enter-done {
        opacity: 1;
    }

    &.fade-appear {
        transform: translateY(1rem);
        opacity: 0;
    }

    &.fade-appear-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.5s 0.1s, opacity 0.5s 0.1s;
    }

    .header {
        color: rgba(#fff, 0.2);
    }

    .sub-header {
        margin-bottom: 0;
        color: rgba(#fff, 0.2);
        text-transform: uppercase;
    }

    .content {
        display: flex;
        justify-content: space-between;
        color: #fff;
    }

    .text-wrap {
        width: 50%;
        padding-right: 1rem;

        @media all and (min-width: 1440px) {
            width: 60%;
        }

        @media all and (min-width: 1880px) {
            width: 45%;
        }
    }

    .text {
        min-height: 14em;

        a {
            word-break: break-word;
        }
    }

    .btns {
        margin-top: 2rem;
        margin-right: -1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .plus-btn {
            margin-right: 1rem;
        }
    }

    .animation-wrap {
        width: 50%;
        height: 0;
        margin-top: -10%;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (min-width: 1440px) {
            width: 40%;
            margin-top: -15%;
        }

        @media all and (min-width: 1880px) {
            width: 55%;
        }

        div {
            max-width: 100%;
        }

        canvas {
            @media all and (min-width: 1880px) {
                max-height: 26rem;
            }
        }
    }
}

.service-videos-container {
    background-color: #00c9b0;
    color: #fff;

    .container {
        margin-bottom: 0;
        padding-top: 6rem;
        padding-bottom: 6rem;
        overflow: hidden;
    }
}

.film-videos {
    background-color: #f546b7;
}

.service-videos {
    margin-bottom: 30px;
    
    @media all and (min-width: 576px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px 30px;
    }

    @media all and (min-width: 768px) {
        margin: 0 -25px 30px;
    }

    // @media all and (min-width: 1024px) {
    //     margin: 0 -50px;
    // }

    .service-video {
        margin-bottom: 20px;
        text-decoration: none;
        display: block;

        @media all and (min-width: 576px) {
            flex: 1 0 50%;
            margin: 0 15px 30px;
            max-width: calc(50% - 30px);
        }

        @media all and (min-width: 768px) {
            flex: 1 0 33.33333%;
            margin: 0 25px 30px;
            max-width: calc(33.33333% - 50px);
        }

        // @media all and (min-width: 1024px) {
        //     flex: 1 0 25%;
        //     max-width: calc(25% - 15px);
        // }

        &:hover {
            .video-hover {
                > div,
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    .video-hover {
        position: relative;
        margin-bottom: 10px;

        > div {
            opacity: 0;
            transition: 0.6s;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            z-index: 2;
            display: flex;
            justify-content: center;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0, 0.4);
            opacity: 0;
            transition: 0.6s;
        }
    }

    .video-image {
        max-width: 100%;
        display: block;
    }

    h3 {
        margin: 0 0 10px;
    }

    p {
        margin: 0;
        font-size: 0.875rem;
    }
}

.service-featured-video {
    display: flex;
    align-items: center;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;

    @media all and (min-width: 768px) {
        min-height: 800px;
    }

    @media all and (min-width: 1200px) {
        min-height: 1080px;
    }

    .featured-content {
        width: 100%;

        p {
            @media all and (min-width: 768px) {
                max-width: 55%;
            }
        }
    }
}