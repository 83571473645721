@import '../../assets/styles/colors';

#site-nav {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 100%;
    left: 0;
    opacity: 0;
    background-color: $blue;
    overflow: auto;
    color: #fff;
    pointer-events: auto;
    transition: top 0s 0.5s, opacity 0.5s;
}

#site-nav-inner {
    min-height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

#site-nav-links {
    width: 100%;
    min-height: 100%;
    padding-top: 9rem;
    padding-bottom: 9rem;
    display: flex;
    flex-direction: column;
    font-weight: 800;
    font-size: 2rem;
    line-height: 1.2;
    text-transform: uppercase;

    @media all and (min-width: 768px) {
        padding-top: 12rem;
        padding-bottom: 12rem;
        font-size: 2.5rem;
    }

    @media all and (min-width: 1440px) {
        padding-top: 16rem;
        padding-bottom: 16rem;
        font-size: 3rem;
    }

    > div {
        margin-top: 0.1em;
        margin-bottom: 0.1em;
    }
}

.site-nav-link {
    position: relative;
    display: inline-block;
    padding-right: 1em;
    text-decoration: none;

    &:hover {
        .icon {
            opacity: 1;
            transition: opacity 0.25s 0.25s;

            &:before, &:after {
                transition: width 0.25s 0.25s, height 0.25s 0.25s;
            }

            &:before {
                width: 100%;
            }

            &:after {
                height: 100%;
            }
        }

        .back-icon {
            opacity: 1;
            transition: opacity 0.25s 0.25s;

            &:before, &:after {
                transition: transform 0.25s 0.25s;
            }

            &:before {
                transform: rotate(-45deg) translateX(0);
            }
    
            &:after {
                transform: rotate(45deg) translateX(0);
            }
        }

        .site-nav-link-inner {
            transform: translateX(1em);
            color: $dark-grey;
            transition: transform 0.5s, color 0.5s;
        }
    }

    .icon {
        width: 0.6em;
        height: 0.6em;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.25s;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background-color: $dark-grey;
            transition: width 0.25s, height 0.25s;
        }

        &:before {
            width: 50%;
            height: 0.15em;
        }

        &:after {
            width: 0.15em;
            height: 50%;
        }
    }

    .back-icon {
        width: 0.5em;
        height: 0.5em;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.25s;

        &:before, &:after {
            content: '';
            width: 100%;
            height: 0.15em;
            position: absolute;
            left: 0;
            background-color: $dark-grey;
            transition: transform 0.25s;
        }

        &:before {
            top: 50%;
            transform: rotate(-45deg) translateX(0.25em);
            transform-origin: top left;
        }

        &:after {
            bottom: 50%;
            transform: rotate(45deg) translateX(0.25em);
            transform-origin: bottom left;
        }
    }
}

.site-nav-link-inner {
    position: relative;
    transform: translateX(0);
    transition: transform 0.5s 0.25s, color 0.5s;
}

#site-nav-social {
    width: 100%;
    position: absolute;
    bottom: 1rem;
    font-size: 1.2rem;

    @media all and (min-width: 768px) {
        font-size: 1.5rem;
    }

    @media all and (min-width: 1024px) {
        bottom: 3rem;
    }

    @media all and (min-width: 1440px) {
        bottom: 6rem;
    }

    .icons {
        margin-top: 0.5em;

        a {
            display: inline-block;
            margin-right: 0.75em;
        }

        img {
            height: 1.2em;
        }
    }
}

.sub-menu-wrap {
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $blue;
    }
}

.sub-menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 100%;
    left: 0;
    z-index: 100;
    opacity: 0;
    background-color: $blue;
    display: flex;
    align-items: center;
    overflow: hidden;
    color: #fff;
    pointer-events: auto;
    transition: top 0s 0.5s, opacity 0.5s;
}

.sub-menu-inner {
    flex-basis: 100%;
    padding-top: 9rem;
    padding-bottom: 9rem;
    transform: translateX(2rem);
    transition: transform 0.5s;

    @media all and (min-width: 768px) {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }

    @media all and (min-width: 1440px) {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
}

.sub-menu-links {
    padding-left: 0.5em;
    font-size: 0.9em;
}

.show-site-nav {
    #site-nav {
        top: 0;
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }

    .show-sub-menu {
        .sub-menu {
            top: 0;
            opacity: 1;
            transition: opacity 0.5s;
    
            #site-nav-inner {
                transform: translateY(0);
                transition: transform 0.5s;
            }
        }
    
        .sub-menu-inner {
            transform: translateX(0);
        }
    }
}
