@import '../../assets/styles/colors';

.work-list {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.work-list-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    > * {
        width: 50%;

        @media all and (min-width: 1024px) {
            width: 33.3333%;
        }
    }
}

.work-small {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 2rem;
    transition: opacity 2s, transform 1s;

    &.fade-enter, &.fade-appear {
        opacity: 0;
        transform: translateY(1rem);
    }

    &.fade-enter-done, &.fade-appear-done {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 2s, transform 1s;
    }

    &.fade-exit {
        opacity: 1;
    }

    &.fade-exit-active {
        opacity: 0;
        transition: opacity 0.25s;
    }

    &.fade-exit-done {
        opacity: 0;
    }

    a {
        display: block;
        text-decoration: none;
    }

    h2 {
        margin-top: 0.5em;
        font-weight: normal;
        font-size: 1rem;

        @media all and (min-width: 375px) {
            font-size: 1.2rem;
        }

        @media all and (min-width: 768px) {
            font-size: 1.5rem;
        }
    }

    &.loading {
        animation: workpulse 2s infinite;

        &:after {
            content: '';
            width: 8em;
            height: 1.2em;
            margin-top: 0.5em;
            display: block;
            background-color: $extra-light-grey;
            font-size: 1.5rem;
        }
    }

    .image {
        position: relative;

        &:before {
            content: '';
            display: block;
            background-color: $extra-light-grey;
            padding-bottom: 127%;
        }

        .lazy-load-image-background {
            display: block !important;
        }

        img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@keyframes workpulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}