@import '../../assets/styles/colors';

#site-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2100;
    padding-top: 1rem;
    padding-bottom: 1rem;
    pointer-events: none;

    @media all and (min-width: 1024px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    &.white-header {
        #site-logo-dark {
            opacity: 0;
        }
        
        #site-logo-white {
            opacity: 1;
        }

        #site-nav-btn {
            div {
                background-color: #fff;
            }
        }
    }
    
    &.fade-appear {
        transform: translateY(1rem);
        opacity: 0;
    }

    &.fade-appear-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }
}

#site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
}

#site-logo {
    position: relative;
    pointer-events: auto;

    a {
        display: block;
    }
}

#site-logo-dark {
    height: 19px;
    display: block;
    opacity: 1;
    transition: opacity 0.5s;
}

#site-logo-white {
    height: 19px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;
}

#site-nav-btn {
    width: 2.2rem;
    height: 2.2rem;
    position: relative;
    pointer-events: auto;

    div {
        width: 100%;
        height: 0.25em;
        background-color: $dark-grey;

        &:first-child, &:nth-child(2) {
            transform: translateX(0);
        }

        &:first-child {
            margin-bottom: 0.25em;
            transition: background-color 0.5s, transform 0.5s 0.3s, opacity 0.5s 0.3s;
        }

        &:nth-child(2) {
            transition: background-color 0.5s, transform 0.5s 0.2s, opacity 0.5s 0.2s;
        }

        &:nth-child(3), &:nth-child(4) {
            position: absolute;
            top: 50%;
            left: 0;
            opacity: 0;
            pointer-events: none;
        }

        &:nth-child(3) {
            transform: translateY(-50%) rotate(45deg) translateX(-2rem);
            transition: background-color 0.5s, transform 0.5s 0.1s, opacity 0.5s 0.1s;
        }

        &:nth-child(4) {
            transform: translateY(-50%) rotate(-45deg) translateX(-2rem);
            transition: background-color 0.5s, transform 0.5s, opacity 0.5s;
        }
    }
}

.show-site-nav {
    #site-logo-dark {
        opacity: 0;
    }
    
    #site-logo-white {
        opacity: 1;
    }

    #site-nav-btn {
        > div {
            background-color: #fff;
            
            &:first-child, &:nth-child(2) {
                opacity: 0;
            }

            &:first-child {
                transform: translateX(1rem);
                transition: background-color 0.5s, transform 0.5s, opacity 0.5s;
            }

            &:nth-child(2) {
                transform: translateX(1rem);
                transition: background-color 0.5s, transform 0.5s 0.1s, opacity 0.5s 0.1s;
            }

            &:nth-child(3), &:nth-child(4) {
                opacity: 1;
            }

            &:nth-child(3) {
                transform: translateY(-50%) rotate(45deg) translateX(0);
                transition: background-color 0.5s, transform 0.5s 0.2s, opacity 0.5s 0.2s;
            }

            &:nth-child(4) {
                transform: translateY(-50%) rotate(-45deg) translateX(0);
                transition: background-color 0.5s, transform 0.5s 0.3s, opacity 0.5s 0.3s;
            }
        }
    }
}

.promo-sidebar {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 0;
    max-width: 100%;
    padding: 0;
    box-shadow: -5px 3px 6px 0 rgba(0, 0, 0, 0.1);
    z-index: 3000;
    transition: 0.6s;

    &.open {
        padding: 30px;
        width: 550px;
    }

    .promo-fade {
        opacity: 0;
        transition: 0.6s;
        height: 100%;

        &.show {
            opacity: 1;
        }
    }

    .promo-inner {
        overflow-y: auto;
        padding: 30px 0;
        height: 100%;
    }

    .promo-pre-header {
        margin-bottom: 0;
    }

    .header-sm {
        margin-top: 0.1em;
        max-width: 90%;
    }

    .promo-close {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            opacity: 0.2;
        }

        .icon {
            width: 14px;
            height: 14px;
            transition: 0.3s;

            &:before, 
            &:after {
                position: absolute;
                content: '';
                height: 14px;
                width: 2px;
                background-color: #58595b;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        .text {
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #707070;
        }
    }

    .promo-image {
        // max-width: 40%;
        display: block;
        margin: 50px auto;
        height: 250px;
    }
}

.site-header-contact-link {
    margin-right: 20px;
    text-decoration: none;
    pointer-events: auto;
    transition: 0.5s;
    font-size: 1.125rem;

    @media all and (max-width: 400px) {
        display: none;
    }

    .icon::before,
    .icon::after {
        transition: 0.5s;
    }
}