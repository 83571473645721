@import '../../assets/styles/colors';

.message-form {
    // padding-top: 6rem;
    // padding-bottom: 6rem;
    // background-color: $dark-green;

    @media all and (min-width: 520px) {
        font-size: 1.5rem;
    }

    @media all and (min-width: 768px) {
        font-size: 2rem;
    }
}

.message-form-inner {
    opacity: 1;
    transition: opacity 0.5s;

    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }

    button {
        font-size: 3em;
        font-weight: 800;
        text-transform: uppercase;
    }

    .name-input-wrap {
        max-width: 26em;
    }

    .phone-input-wrap {
        max-width: 26em;
    }
}

.message-success {
    text-align: center;
    color: #fff;
    font-weight: 800;
}