@import '../../assets/styles/colors';

.about-content {
    transition: background-color 0.5s, color 0.5s;
    overflow: hidden;

    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s, background-color 0.5s, color 0.5s;
    }
}

.about {
    position: relative;
}

.about-inner {
    padding-top: 4.5rem;

    @media all and (min-width: 1024px) {
        min-height: 56.25vw;
        display: flex;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    @media all and (min-width: 1024px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }
}

.about-container {
    width: 100%;
}

.about-step {
    height: 100%;
    position: relative;
    z-index: 10;

    @media all and (min-width: 1024px) {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &.white {
        color: #fff;
    }

    &.dark-grey {
        color: $dark-grey;
    }

    .header {
        opacity: 0.2;
        
        @media all and (min-width: 1024px) {
            font-size: 6rem;
        }
        
        @media all and (min-width: 1440px) {
            font-size: 8rem;
        }

        @media all and (min-width: 1880px) {
            font-size: 10rem;
        }
        
        &.hide {
            height: 0;
            opacity: 0;
            transition: height 0s 0.5s, opacity 0.5s;
        
            @media all and (min-width: 1024px) {
                height: 4rem;
            }
        }
    }
}

.about-text {
    @media all and (min-width: 1024px) {
        flex: 1;
    }
}