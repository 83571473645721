.home-clients {
    margin-top: 6rem;
    margin-bottom: 6rem;
    overflow: hidden;

    @media all and (min-width: 1024px) {
        margin-top: 9rem;
        margin-bottom: 9rem;
    }

    @media all and (min-width: 1440px) {
        margin-top: 12rem;
        margin-bottom: 12rem;
    }
}

.home-clients-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -3rem;
    margin-left: -2rem;
    margin-right: -2rem;

    @media all and (min-width: 540px) {
        margin-bottom: -6rem;
        margin-left: -3rem;
        margin-right: -3rem;
    }

    @media all and (min-width: 1024px) {
        margin-bottom: -5rem;
        margin-left: -4rem;
        margin-right: -4rem;
    }

    @media all and (min-width: 1880px) {
        margin-left: -6rem;
        margin-right: -6rem;
    }

    > div {
        width: 50%;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: 3rem;
        text-align: center;

        @media all and (min-width: 540px) {
            width: 33.3333%;
            padding-left: 3rem;
            padding-right: 3rem;
            margin-bottom: 6rem;
        }

        @media all and (min-width: 1024px) {
            width: 25%;
            padding-left: 4rem;
            padding-right: 4rem;
        }

        @media all and (min-width: 1880px) {
            padding-left: 6rem;
            padding-right: 6rem;
        }

        .lazy-load-image-background {
            display: block !important;
        }

        img {
            max-width: 100%;
            max-height: 4rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
