.platforms-section {
  background: #F0F0F0;
  padding-top: 50px;
  padding-bottom: 50px;

  @media all and (min-width: 1024px) {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .platforms-inner {
    color: #414042;

    .platforms-text {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;

      @media all and (min-width: 1024px) {
        flex-direction: row;
        margin-bottom: 100px;
      }

      .header-container {
        @media all and (min-width: 1024px) {
          width: 40%;
        }

        .header-sm {
          margin-top: 0.25em;
          margin-bottom: 0.25em;
          font-size: 28px;
          line-height: 1em;
          text-transform: initial;

          @media all and (min-width: 768px) {
            font-size: 32px;
          }

          @media all and (min-width: 1024px) {
            font-size: 40px;
            margin-right: 30px;
          }

          @media all and (min-width: 1440px) {
            font-size: 50px;
          }
        }
      }

      .content-container {
        @media all and (min-width: 1024px) {
          width: 60%;
        }
      }
    }
  }

  .platforms-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto -20px;
    flex-wrap: wrap;

    .platform-logo {
      width: calc(100% - 30px);
      display: flex;
      margin: 30px;
      justify-content: center;
      align-items: center;
      background: #fff;
      flex-grow: 1;
      height: 314px;
      padding: 40px;

      @media all and (min-width: 768px) {
        width: calc(50% - 40px);
        margin: 20px;
      }

      @media all and (min-width: 1024px) {
        width: calc(33.33% - 40px);
      }

      img {
        width: 100%;
      }
    }
  }

}