@import '../../assets/styles/colors';

.error-404-content {
    padding-top: 6rem;
    padding-bottom: 6rem;
    position: relative;
    z-index: 10;
    text-align: center;
    font-size: 1.2rem;

    @media all and (min-width: 1024px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
        font-size: 1.4rem;
    }

    @media all and (min-width: 1440px) {
        font-size: 1.6rem;
    }
    
    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }

    .header {
        margin-bottom: 0.5em;
        text-transform: none;
    }
}

.error-404-animation {
    margin-top: -10vw;
    position: relative;
    z-index: -1;
}

.error-404-image {
    margin-top: 2rem;
    position: relative;
    z-index: 10;

    > div {
        width: 100%;
        position: absolute;
        bottom: 1vw;
        z-index: -1;
        text-align: center;
        font-size: 34vw;
        font-weight: bold;
        color: $light-grey;
        line-height: 0.7;
    }
    
    img {
        width: 100%;
    }
}