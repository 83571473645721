@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-RegularItalic'), url(../fonts/Gilroy-RegularItalic.woff2) 
        format('woff2'), url(../fonts/Gilroy-RegularItalic.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-ExtraBoldItalic'), url(../fonts/Gilroy-ExtraBoldItalic.woff2) 
        format('woff2'), url(../fonts/Gilroy-ExtraBoldItalic.woff) format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-ExtraBold'), url(../fonts/Gilroy-ExtraBold.woff2) 
        format('woff2'), url(../fonts/Gilroy-ExtraBold.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-Regular'), url(../fonts/Gilroy-Regular.woff2) 
        format('woff2'), url(../fonts/Gilroy-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}