$grey: #BCBEC0;
$extra-dark-grey: #272727;
$dark-grey: #58595B;
$light-grey: #D9D9D9;
$extra-light-grey: #F8F8F9;

$purple: #8456A1;
$blue: #007DCB;
$light-blue: #44C1DC;
$orange: #FDA602;
$green: #00C9B0;
$dark-green: #00C2AF;
$red: #F67287;
$beige: #DFBEA6;
$pink: #F546B7;
