@import '../../assets/styles/colors';

#site-footer {
    background-color: $extra-light-grey;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 0.9rem;

    @media all and (min-width: 1024px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
        font-size: 0.8rem;
    }

    a {
        text-decoration: none;
    }
}

#footer-logo {
    max-width: 12rem;

    @media all and (min-width: 768px) {
        max-width: 14rem;
    }
}

.cookie-banner {
    display: block;
    background-color: #fff;
    position: fixed;
    padding: 20px;
    z-index: 1001;

    @media all and (min-width: 768px) {
        max-width: 50%;
        padding: 50px;
    }

    button {
        width: 100%;
        background-color: #58595b;
        color: #fff;
        text-transform: uppercase;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        margin-top: 30px;
    }
}

.footer-socials {
    display: flex;
    align-items: center;
    margin-top: 1.5em;

    .social-link {
        display: block;
        margin-right: 20px;
        line-height: 0;

        svg {
            max-width: 26px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}