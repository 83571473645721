@import '../../assets/styles/colors';

.news-content {
    background-color: $light-blue;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media all and (min-width: 1024px) {
        padding-top: 9rem;
        padding-bottom: 9rem;
    }

    .header {
        margin-bottom: 0.5em;
        opacity: 0.2;
        color: #fff;
    }
}

.news-list {
    margin-bottom: -2rem;

    @media all and (min-width: 768px) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    @media all and (min-width: 1024px) {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -4rem;
    }

    @media all and (min-width: 1440px) {
        margin-top: 4rem;
    }

    @media all and (min-width: 1880px) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.news-small {
    margin-bottom: 2rem;

    @media all and (min-width: 768px) {
        width: 50%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    @media all and (min-width: 1024px) {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 4rem;
    }

    @media all and (min-width: 1880px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &.loading {
        animation: pulse 2s infinite;

        .image {
            > div {
                &:before {
                    content: '';
                    width: 100%;
                    padding-bottom: 124%;
                    display: block;
                    background-color: rgba($extra-light-grey, 0.2);
                }
            }
        }

        .meta {
            &:before {
                content: '';
                width: 15em;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
            }
        }

        .header {
            margin-top: 0.5em;

            &:before, &:after {
                content: '';
                width: 100%;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
            }
        }

        .plus-icon {
            opacity: 0.2;
        }
    }

    &.fade-appear {
        opacity: 0;
        transform: translateY(1rem);
    }

    &.fade-enter-done {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 2s, transform 1s;
    }

    &.fade-exit-done {
        opacity: 0;
    }

    a {
        display: block;
        overflow: hidden;
        text-decoration: none;

        &:hover {
            .header {
                color: $dark-grey;
                text-decoration: underline;
            }

            .plus-icon {
                &:before, &:after {
                    background-color: $dark-grey;
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}

.news-small-inner {
    display: flex;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @media all and (min-width: 1880px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    > div {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        @media all and (min-width: 1880px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .image {
        width: 40%;

        @media all and (min-width: 1880px) {
            width: 50%;
        }

        img {
            max-width: 100%;
        }
    }

    .text {
        width: 60%;
        display: flex;
        flex-direction: column;

        @media all and (min-width: 1880px) {
            width: 50%;
        }
    }

    .text-inner {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media all and (min-width: 1440px) {
            padding-top: 3rem;
        }

        @media all and (min-width: 1880px) {
            padding-top: 4rem;
        }
    }

    .header {
        margin-top: 0;
        margin-bottom: 0;
        opacity: 1;
        font-size: 1.8rem;
        transition: color 0.25s;
    }

    .meta {
        margin-bottom: 0.5em;
        display: flex;
        font-size: 0.8rem;
        color: #fff;

        @media all and (min-width: 1440px) {
            font-size: 0.9rem;
        }

        @media all and (min-width: 1880px) {
            font-size: 1rem;
        }

        > div {
            margin-right: 2em;
        }

        .icon {
            height: 1em;
            margin-bottom: -0.1em;
            margin-right: 0.5em;
        }
    }

    .plus-icon {
        width: 2rem;
        height: 2rem;
        position: relative;

        @media all and (min-width: 1440px) {
            width: 2.5rem;
            height: 2.5rem;
        }

        @media all and (min-width: 1880px) {
            width: 4rem;
            height: 4rem;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            background-color: #fff;
            transition: background-color 0.25s;
        }

        &:before {
            width: 100%;
            height: 4px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            @media all and (min-width: 1024px) {
                height: 6px;
            }

            @media all and (min-width: 1440px) {
                height: 8px;
            }

            @media all and (min-width: 1880px) {
                height: 14px;
            }
        }

        &:after {
            width: 4px;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @media all and (min-width: 1024px) {
                width: 6px;
            }

            @media all and (min-width: 1440px) {
                width: 8px;
            }

            @media all and (min-width: 1880px) {
                width: 14px;
            }
        }
    }
}


/*
.news-content {
    background-color: $light-blue;

    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.news-small {
    &:first-child {
        .news-small-inner {
            padding-top: 6rem;

            @media all and (min-width: 768px) {
                padding-top: 9rem;
            }

            .container {
                position: relative;
                
                &:before {
                    content: none;
                }
            }
            
            .header {
                text-decoration: underline;
                color: $extra-dark-grey;
            }
        }
    }

    &:last-child {
        .news-small-inner {
            padding-bottom: 6rem;

            @media all and (min-width: 768px) {
                padding-bottom: 9rem;
            }
        }
    }

    &.fade-appear {
        opacity: 0;
        transform: translateY(1rem);
    }

    &.fade-enter-done {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 2s, transform 1s;
    }

    &.fade-exit-done {
        opacity: 0;
    }

    &.loading {
        .meta {
            &:before {
                content: '';
                width: 15em;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
                animation: pulse 2s infinite;
                animation-delay: inherit;
            }
        }

        .header {
            margin-top: 1em;
            margin-bottom: 0.5em;

            &:before {
                content: '';
                width: 100%;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
                animation: pulse 2s infinite;
                animation-delay: inherit;
            }

            &:after {
                content: '';
                width: 10em;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
                animation: pulse 2s infinite;
                animation-delay: inherit;
            }
        }

        .more {
            &:before {
                content: '';
                width: 8em;
                height: 1em;
                max-width: 100%;
                display: inline-block;
                background-color: rgba($extra-light-grey, 0.2);
                animation: pulse 2s infinite;
                animation-delay: inherit;
            }
        }
    }
}

@keyframes pulse {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 50%;
    }
    100% {
        opacity: 100%;
    }
}

.news-small-inner {
    position: relative;
    display: block;
    padding-bottom: 2rem;
    color: #fff;
    text-decoration: none;

    @media all and (min-width: 768px) {
        padding-bottom: 4rem;
    }

    .container {
        position: relative;
        
        &:before {
            content: '';
            width: 50%;
            height: 2px;
            display: block;
            margin-top: -2px;
            margin-bottom: 4rem;
            background-color: #40ABC3;

            @media all and (min-width: 768px) {
                height: 3px;
                margin-top: -4px;
                margin-bottom: 4rem;
            }

            @media all and (min-width: 1024px) {
                height: 4px;
            }
        }
    }

    &:hover {
        .overlay {
            opacity: 0.3;
        }
    }

    .meta {
        display: flex;
        font-size: 0.9rem;

        > div {
            margin-right: 2em;
        }

        .icon {
            height: 1em;
            margin-bottom: -0.1em;
            margin-right: 0.5em;
        }
    }

    .header {
        margin-bottom: 0.5em;
        font-size: 2.5rem;

        @media all and (min-width: 1024px) {
            font-size: 3rem;
        }

        @media all and (min-width: 1880px) {
            font-size: 3.5rem;
        }
    }

    .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;
    }
}
*/