.work-video {
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-bottom: 56.25%;
    }

    > div {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }
}
