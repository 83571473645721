.ecommerce-stats {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 50px;

  @media all and (min-width: 1024px) {
    height: 100vh;
  }

  .plus-btn {
    color: #fff;

    .icon {
      &:before, &:after {
        background-color: #fff;
      }
    }
  }

  &-inner {
    background: #414042;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;

    @media all and (min-width: 1024px) {
      flex-direction: row;
    }

    .col1, .col2 {
      width: 100%;

      @media all and (min-width: 1024px) {
        width: 50%;
      }
    }

    .col1 {
      margin-bottom: 50px;

      @media all and (min-width: 1024px) {
        margin-bottom: 0;
      }
    }

    .col2 {
      @media all and (min-width: 1024px) {
        padding-left: 150px;
      }
    }

    .large-percent-text {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 50px;
    }

    .stats-header {
      font-size: 32px;
      margin-bottom: 100px;

      @media all and (min-width: 1024px) {
        font-size: 42px;
      }

      @media all and (min-width: 1440px) {
        font-size: 50px;
      }
    }

    .stat-1 {
      font-weight: bold;
      color: #1FC2AF;
      display: flex;
      align-items: flex-end;
      position: relative;
      line-height: 0.75;
      width: 100%;

      @media all and (min-width: 1440px) {
        justify-content: flex-end;
      }

      .stat-number {
        font-size: 120px;

        @media all and (min-width: 768px) {
          font-size: 220px;
        }

        @media all and (min-width: 1024px) {

        }

        @media all and (min-width: 1440px) {
          font-size: 320px;
        }

        @media all and (min-width: 1880px) {
          font-size: 420px !important;
        }
      }

      .stat-percent {
        font-size: 80px;

        @media all and (min-width: 1024px) {
          font-size: 120px;
        }
      }
    }
  }
}