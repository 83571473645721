@import '../../assets/styles/colors';

.careers-content {
    padding-top: 6rem;
    padding-bottom: 9rem;

    @media all and (min-width: 768px) {
        padding-top: 9rem;
        padding-bottom: 12rem;
    }

    @media all and (min-width: 1024px) {
        padding-bottom: 18rem;
    }

    &.fade-appear {
        opacity: 0;
    }

    &.fade-appear-done {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.careers-text {
    max-width: 35em;
    margin-bottom: 3rem;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }

    @media all and (min-width: 768px) {
        margin-bottom: 6rem;
        font-size: 1.5rem;
    }

    @media all and (min-width: 1440px) {
        font-size: 1.8rem;
    }

    .sub-header {
        margin-bottom: 0;
    }

    .aboard-header {
        margin: 0;
        font-size: 1.5rem;
        color: $grey;
        font-weight: bold;

        @media all and (min-width: 768px) {
            font-size: 2rem;
        }

        @media all and (min-width: 1440px) {
            font-size: 2.5rem;
        }
    }
}

.career-opportunities {
    margin-bottom: 3rem;

    @media all and (min-width: 768px) {
        margin-bottom: 6rem;
    }

    .header {
        font-size: 1.5rem;
        font-weight: bold;

        @media all and (min-width: 768px) {
            font-size: 2rem;
        }

        @media all and (min-width: 1440px) {
            font-size: 2.5rem;
        }
    }
}

.career-opportunities-list {
    @media all and (min-width: 420px) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        margin-bottom: -3rem;    
    }

    @media all and (min-width: 1440px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.career-small {
    margin-bottom: 3rem;

    @media all and (min-width: 420px) {
        width: 50%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    @media all and (min-width: 1024px) {
        width: 33.3333%;
    }

    @media all and (min-width: 1440px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &.fade-appear {
        opacity: 0;
        transform: translateY(1rem);
    }

    &.fade-enter-done {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 2s, transform 1s;
    }
}

.career-small-inner {
    display: block;
    text-decoration: none;

    .animation-wrap {
        position: relative;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        > div {
            width: 100%;
            height: 100%;
            padding: 1.5rem;
            position: absolute;
            top: 0;
            left: 0;

            @media all and (min-width: 640px) {
                padding: 2rem;
            }

            @media all and (min-width: 1440px) {
                padding: 3rem;
            }

            > div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        canvas {
            max-width: 100%;
            max-height: 100%;
        }
    }

    h1 {
        margin-top: 0.75em;
        margin-bottom: 0.5em;
        font-size: 1.5rem;
    }

    .image-placeholder {
        &:before {
            content: '';
            width: 100%;
            display: block;
            padding-bottom: 100%;
            background-color: $extra-light-grey;
        }
    }

    .plus-btn {
        font-size: 1.2rem;
    }
}