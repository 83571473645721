@import '../../assets/styles/colors';
@import '../../assets/styles/slick';

.home-services {
    overflow: hidden;

    .slider-wrapper {
        position: relative;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;
    }

    .slick-slide > div,
    .slick-slide > div > div,
    .slick-slide > div > div > div { 
        height: 100%; 
    }

    .slider {
        .slide-wrapper {
            background-size: contain;
            background-position: center right;
            background-repeat: no-repeat;
            display: flex;
            align-items: center;
            padding: 20px;
            color: #fff;

            @media all and (min-width: 768px) {
                padding: 50px;
                min-height: 800px;
            }

            @media all and (min-width: 1200px) {
                padding: 120px;
                min-height: 1080px;
            }

            @media all and (max-width:767px) {
                background-image: none !important;
            }

            .slide-content {
                margin-top: 6rem;
                margin-bottom: 6rem;
                overflow: hidden;
                width: 100%;
            }

            .sub-header {
                @media all and (min-width: 768px) {
                    max-width: 50%;
                }

                @media all and (min-width: 1200px) {
                    max-width: 550px;
                }
            }
            
            .header-lg {
                margin-bottom: 1.875rem;
                opacity: 0.2;
                color: #fff;
            }

            .slide-image {
                max-width: 80%;
                margin-top: 30px;

                @media all and (min-width: 768px) {
                    display: none;
                }
            }
        }
    }

    .slick-arrow {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1;

        @media all and (min-width: 768px) {
            right: 50px;
            bottom: 40px;
        }

        @media all and (min-width: 1200px) {
            right: 120px;
        }

        &:before {
            content: '';
        }

        img {
            width: 20px;

            @media all and (min-width: 768px) {
                width: 40px;
            }
        }

        &.slick-disabled {
            opacity: 0.2;
        }

        &.slick-next {
            @media all and (min-width: 768px) {
                right: 40px;
            }
        }

        &.slick-prev {
            left: 20px;

            //@media all and (min-width: 768px) {
            //    left: 110px;
            //}
            //
            //@media all and (min-width: 1200px) {
            //    left: 180px;
            //}
        }
    }
}