.work-banner {
    position: relative;
    overflow: hidden;

    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-position: top center;
        background-size: cover;
    }

    .background-image {
        width: 100%;
        display: block;
        visibility: hidden;
    }
}

.work-banner-inner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    text-align: center;

    &.logo-top {
        justify-content: flex-start;
    }

    &.logo-bottom {
        justify-content: flex-end;
    }

    .logo {
        padding: 3rem;

        @media all and (min-width: 1024px) {
            padding: 6rem;
        }


        img {
            max-width: 100%;
            max-height: 9rem;
        }
    }
}
