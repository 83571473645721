@import '../../assets/styles/colors';

.home-banner {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-align: center;

    &.rock-banner {
        background-color: $orange;

        .home-banner-inner {
            padding-bottom: 6rem;
        }
    }

    &.monster-truck-banner {
        background-color: #44C1DC;

        .clouds {
            max-height: 40%;
            position: absolute;
            bottom: 92%;
            left: 50%;
            z-index: -1;
            transform: translateX(-50%);
        }
    }

    &.space-banner {
        background-color: #181719;
        background-image: url(../../assets/images/home-banner-space-bg.jpg);
        background-position: center;
        background-size: cover;
    }

    .light {
        width: 100%;
        max-width: 20rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding-bottom: 6rem;

        @media all and (min-width: 768px) {
            max-width: 28rem;
        }

        @media all and (min-width: 1024px) {
            max-width: 40rem;
        }

        @media all and (min-width: 1440px) {
            max-width: 50rem;
        }

        > div {
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/home-banner-light.svg');
            background-size: 100% 100%;
            position: relative;

            &:before {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(0deg, rgba($orange,1) 0%, rgba($orange,0) 10%, rgba($orange,0) 100%);
            }
        }
    }
}

.home-banner-inner {
    max-width: 100%;
    flex-basis: 100%;
    padding-top: 6rem;
    position: relative;
    z-index: 10;
    font-size: 4rem;

    @media all and (min-width: 768px) {
        font-size: 6rem;
    }

    @media all and (min-width: 1024px) {
        font-size: 8rem;
    }

    @media all and (min-width: 1660px) {
        padding-top: 9rem;
        font-size: 10rem;
    }

    > div {
        position: relative;
    }

    .header-lg {
        margin-bottom: -0.4em;
        text-align: center;
        color: #fff;
        font-size: inherit;
    }
}

.monster-rock-stage {
    position: relative;
    pointer-events: none;
    font-size: 1.5em;

    @media all and (min-width: 768px) {
        font-size: 1em;
    }
}

.monster-rock-solo {
    height: 2.8em;
    margin-bottom: -0.15em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;

    div {
        height: 100% !important;
    }

    canvas {
        height: 100% !important;
        max-width: none;
        margin-left: auto;
        margin-right: auto;
    }
}

.animation-wrap {
    &.monster-rock-amps-left, &.monster-rock-amps-right {
        height: 160%;
        position: absolute;
        bottom: 5%;

        div {
            height: 100% !important;
        }

        canvas {
            height: 100% !important;
            max-width: none;
        }
    }
}

.monster-rock-amps-left {
    right: 50%;
    margin-right: 0;

    @media all and (min-width: 768px) {
        margin-right: 0.5em;
    }

    > div {
        > div {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.monster-rock-amps-right {
    left: 50%;
    margin-left: -0.5em;

    @media all and (min-width: 768px) {
        margin-left: 0;
    }
}

.monster-truck-stage {
    margin-bottom: 6vw;
    position: relative;
    pointer-events: none;
    font-size: 1.5em;

    @media all and (min-width: 768px) {
        font-size: 1em;
    }
}

.monster-truck {
    height: 2.6em;
    margin-bottom: -0.15em;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;

    div {
        height: 100% !important;
    }

    canvas {
        height: 100% !important;
        max-width: none;
        margin-left: auto;
        margin-right: auto;
    }
}

.animation-wrap {
    &.monster-truck-tree-left, &.monster-truck-tree-right {
        width: auto;
        height: 150%;
        position: absolute;
        bottom: 0.8%;

        div {
            height: 100% !important;
        }

        canvas {
            height: 100% !important;
            max-width: none;
        }
    }

    &.monster-truck-turtle {
        width: auto;
        height: 25%;
        position: absolute;
        bottom: -5%;
        z-index: 20;

        div {
            height: 100% !important;
        }

        canvas {
            height: 100% !important;
            max-width: none;
        }
    }
}

.monster-truck-turtle {
    right: 50%;
    margin-right: 0.4em;

    > div {
        > div {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.monster-truck-tree-left {
    right: 50%;
    margin-right: 1em;

    > div {
        > div {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.monster-truck-tree-right {
    left: 50%;
    margin-left: 1em;
}

.space-stage {
    display: flex;
    justify-content: center;

    @media all and (min-width: 768px) {
        padding-left: 20%;
    }

    .planet {
        display: none;

        @media all and (min-width: 768px) {
            max-width: 20rem;
            display: block;
            margin-right: -8rem;
        }

        @media all and (min-width: 1024px) {
            max-width: 30rem;
            margin-right: -10rem;
        }

        @media all and (min-width: 1880px) {
            max-width: 30rem;
        }

        canvas {
            @media all and (min-width: 768px) {
                margin-left: auto;
            }
        }
    }

    .astro-monster {
        max-width: 30rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-bottom: -15rem;

        @media all and (min-width: 1024px) {
            max-width: 35rem;
        }

        @media all and (min-width: 1880px) {
            max-width: 40rem;
        }

        canvas {
            margin-left: auto;
            margin-right: auto;

            @media all and (min-width: 768px) {
                margin-left: 0;
            }
        }
    }
}

.hero-video-wrapper {
    position: relative;
    min-height: 400px;
    background: #0c0b10;
    display: block;

    @media all and (min-width: 1440px) {
        height: 100vh;
        overflow: hidden;
        display: flex;
    }

    video {
        width: 100%;
        display: block;

        @media all and (min-width: 1440px) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            overflow: hidden;
            z-index: 0;
        }
    }

    .home-banner-inner {
        padding-top: 0;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: textFadeIn;
        animation-delay: 12s;
    }

    @media all and (max-width: 1440px) {
        .home-banner-inner,
        .home-banner-inner > div {
            position: static;
        }

        .home-banner-inner {
            top: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@keyframes textFadeIn {
   0% {opacity: 0;}
   100% {opacity: 1;}
}